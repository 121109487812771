import React from 'react'
import './Bookingss.css'

function Bookingss() {
    return (
        <div>
            <nav class="navbar">
                <img src="https://via.placeholder.com/150" alt="Logo" />
                <ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Contact Us</a></li>
                </ul>
            </nav>
            <div class="fullscreen-background">
                <div class="welcome-message">Welcome to Cinchona Guesthouse booking system</div>
                <div class="search-bar">
                    <div class="input-row">
                        <div class="input-group">
                            <label for="location">Location of Room</label>
                            <select id="location">
                                <option value="" disabled selected>Choose Location</option>
                                <option value="room1">Room 1</option>
                                <option value="room2">Room 2</option>
                                <option value="room3">Room 3</option>
                            </select>
                        </div>
                        <div class="input-group">
                            <label for="room-type">Type of Room</label>
                            <select id="room-type">
                                <option value="" disabled selected>Choose Room Type</option>
                                <option value="single">Single</option>
                                <option value="double">Double</option>
                                <option value="suite">Suite</option>
                            </select>
                        </div>
                        <div class="input-group">
                            <label for="guest-type">Type of Guest</label>
                            <select id="guest-type">
                                <option value="" disabled selected>Choose Guest Type</option>
                                <option value="adult">Adult</option>
                                <option value="child">Child</option>
                            </select>
                        </div>
                    </div>
                    <div class="input-row">
                        <div class="input-group">
                            <label for="checkin">Check in date</label>
                            <input type="date" id="checkin" name="checkin" />
                        </div>
                        <div class="input-group">
                            <label for="checkout">Check out date</label>
                            <input type="date" id="checkout" name="checkout" />
                        </div>
                    </div><br />
                    <button type="button" class="search-button">Search</button>
                </div>
            </div>
        </div>
    )
}

export default Bookingss