import React from 'react';
import './Bookingss2.css'

function Bookingss2() {
    return (
        <div>
            <nav className="navbar">
                <img src="https://via.placeholder.com/150" alt="Logo" />
                <ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Contact Us</a></li>
                </ul>
            </nav>
            <div className="container" style={{ display: 'flex', height: 'calc(100vh - 60px)' }}>
                <div className="image-section" style={{ flex: 1, backgroundImage: 'url(https://via.placeholder.com/800x600)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div
                        className="image-section"
                        style={{
                            marginTop: '90px',
                            flex: 1,
                            backgroundImage: 'url(https://via.placeholder.com/800x600)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'relative',
                        }}
                    >
                        <div style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '20px',
                            padding: '10px',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark background
                            color: 'white',
                            borderRadius: '5px',
                        }}>
                            <h1>ABC Room</h1>
                        </div>
                    </div>
                </div>
                <div className="form-section" style={{ flex: 1, padding: '20px', overflowY: 'auto', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                    <h1>Booking Form</h1>
                    <div className="input-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="address">Address</label>
                        <input type="text" id="address" name="address" required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="mobile">Mobile No.</label>
                        <input type="tel" id="mobile" name="mobile" required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="id-type">ID Type</label>
                        <select id="id-type" name="id-type" required>
                            <option value="" disabled defaultValue>Choose ID Type</option>
                            <option value="passport">Passport</option>
                            <option value="national-id">National ID</option>
                            <option value="driver-license">Driver License</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="id-number">ID Number</label>
                        <input type="text" id="id-number" name="id-number" required />
                    </div>
                    <button type="button" className="book-button">Book Now</button>
                </div>
            </div>
        </div>
    );
}

export default Bookingss2;
