import React from 'react'
import './Login.css'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom'

function Login() {
    const navigate = useNavigate()
    const myLogin = (e) => {
        e.preventDefault()
        const email = document.getElementById('login__dropdown').value + '@gmail.com'
        const password = document.getElementById('login__input').value
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                localStorage.setItem('userId', document.getElementById('login__dropdown').value);
                    navigate('/office') 
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage)
            });
    }

    return (
        <div className='login__container'>
            <h1 className='login__headerText'>Cinchona Guesthouse</h1>
            <div className='login__screen'>
                <div className='login__screen__content'>
                    <form className='login'>
                        <div className=''>
                            <select id='login__dropdown'>
                                <option value='0'>Login as :</option>

                                <option value='1'>Jalsa Bungalow</option>
                                <option value='2'>Dalgaon</option>
                                <option value='3'>Sailang Niwas</option>
                                <option value='4'>Orchid Hut</option>
                                <option value='5'>Mungpoo Treehouse</option>
                            </select>
                        </div>
                        <div className='login__field'>
                            <i className='login__icon fas fa-lock'></i>
                            <input type='password' className='login__input' placeholder='6-digit PIN' id='login__input' autoComplete='off' />
                        </div>
                        <button className='login__submit' onClick={myLogin} type='submit'>
                            <span className='login__button__text'>Log In Now</span>
                            <i className='login__button__icon fas fa-chevron-right'></i>
                        </button>
                    </form>
                </div>
                <div className='login__screen__background'>
                    <span className='login__screen__background__shape login__screen__background__shape4'></span>
                    <span className='login__screen__background__shape login__screen__background__shape3'></span>
                    <span className='login__screen__background__shape login__screen__background__shape2'></span>
                    <span className='login__screen__background__shape login__screen__background__shape1'></span>
                </div>
            </div>
        </div>
    )
}

export default Login