import React from 'react'
import './Booking.css'
import { doc, getDoc } from "firebase/firestore";
import { loadStripe } from '@stripe/stripe-js';
import { db } from '../firebase';

function Booking({ roomid, type }) {

    return (
        <div className='booking'>
          Oiiiiii!!! Dont hack broooooo
        </div>
    )
}

export default Booking